/*-----------------------------------------------
|   Button
-----------------------------------------------*/


/*-----------------------------------------------
|   Button Reveal
-----------------------------------------------*/
.btn-reveal-trigger {
  @include hover-focus {
    .btn-reveal {
      box-shadow: $btn-reveal-hover-shadow;
      &:hover,
      &:focus,
      &:active{
        color: var(--#{$variable-prefix}btn-reveal-color) !important;
      }
    }
  }
  .btn-reveal {
    &:active:focus,
    &:active,
    &:focus {
      box-shadow: none;
      color: var(--#{$variable-prefix}btn-reveal-color) !important;
      background-color: var(--#{$variable-prefix}btn-reveal-bg);
      border-color: var(--#{$variable-prefix}btn-reveal-border-color);
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
}

/*-----------------------------------------------
|   Falcon buttons
-----------------------------------------------*/
$theme-falcon-btn-colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "default": $dark,
);

@each $color, $value in $theme-falcon-btn-colors {
  .btn-falcon-#{$color} {
    @include falcon-button-variant($color);
  }
}

/*-----------------------------------------------
|   Brand Outline Buttons
-----------------------------------------------*/
.btn-outline-facebook {
  @include button-outline-variant($facebook);
}
.btn-outline-twitter {
  @include button-outline-variant($twitter);
}
.btn-outline-google-plus {
  @include button-outline-variant($google-plus);
}

/*-----------------------------------------------
|   Profile Page Introduction
-----------------------------------------------*/
.btn-intro-collapse {
  .less {
    display: none;
  }
  &[aria-expanded="true"] {
    .less {
      display: inline;
    }
    .full {
      display: none;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                Dark override                               */
/* -------------------------------------------------------------------------- */
.dark {
  .btn-light {
    @extend .btn-dark;
  }
  .btn.disabled, .btn:disabled {
    box-shadow: none !important;
  }
}